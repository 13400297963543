<template>
<main class="main" role="main">
    <div class="main-container">

        <div class="timezone-container mw-100">
            <div class="heading">
                Time zone GMT-{{tz}}
            </div>
            <p>
                List of time zones with GMT offset of -39600 seconds (-11 hours).  (Current local time is same for all cities in this list.)
            </p>
             <div class="auto">
                <autocomplete @selected="setCity" placeholder="Search for Regions / Cities" ref="autocomplete" v-model="cityAuto" :source= "$store.state.api_url + '_/_search?q='" results-property="city" results-display="city">
                </autocomplete>
            </div><br>
            <div class="timezone-flex">
                <a :href="$router.resolve({name: 'region', params: {region: r.B.toLowerCase(), city: r.C}}).href" v-for="r in regions" v-bind:key="r.D">{{r.D}}</a>
                <!-- <router-link :to="{path:'/'+r.B.toLowerCase()+'/'+r.C}" v-for="r in regions" v-bind:key="r.D">{{r.D}}</router-link> -->
                <img v-if="loading" src="@/assets/loading.gif">
            </div>
           

        </div>
    

    <footers></footers>
    </div>
</main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import footers from '@/views/Footer.vue'
import Autocomplete from 'vuejs-auto-complete'
export default {
    components: {
        footers,
        Autocomplete
    },
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            regions: [],
            tz: false,
            loading: false,
            cityAuto: ""
            //api_url:"http://localhost:5000/_read",
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                this.tz = this.$route.params.region
                this.init()
                console.log(3)
                setTimeout(d=>{
                    // waldo.refreshTag("waldo-tag-12333")
                    console.log(3)
                },1000);
            }
        }
    },
    created() {
        this.tz = this.$route.params.q
        this.init()
    },
    methods: {
        setCity() {
            setTimeout(f => {
                this.$router.push({
                    path: "/" + this.cityAuto
                });
            }, 200)

        },
        init() {
            this.loading = true;
            this.regions = []
            axios.post(this.$store.state.api_url + "data", {
                gmt: this.$route.params.q
            }).then(response => {
                this.regions = (response.data.data)
                this.loading = false
            }).catch(error => {

            })
        }
    }
}
</script>

<style scoped>
.auto {
    width: 97%;
    margin: 0 auto;
    margin-top: 25px;
}
</style>
